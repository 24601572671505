.coolNumber-doc {
  @include margin-bottom-50;
  @include tablet {
    @include margin-bottom-100;
  }
}

.coolNumber-doc__title {
  @include margin-bottom-20;
  @include breakpoint690 {
    @include margin-bottom-50;
  }
}

.coolNumber-doc__list {
  @include flex-all-center;
  flex-direction: column;
  gap: 20px;
  counter-reset: numbering;
  @include tablet {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
  }
  @include desktop {
    gap: 0;
    justify-content: space-between;
  }
}

.coolNumber-doc__item {
  @include flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
  width: 250px;
  height: 250px;
  border: 2px solid $orange;
  border-radius: 20px;
  counter-increment: numbering;
  padding: 20px 10px;
  text-align: center;
  &::before {
    content: '№'counter(numbering);
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -5%);
  }
  &:nth-child(1) {
    background: url(../img/svg/cool-number-doc-1.svg) center / 80px no-repeat $black-bg;
  }
  &:nth-child(2) {
    background: url(../img/svg/cool-number-doc-2.svg) center / 80px no-repeat $black-bg;
  }
  &:nth-child(3) {
    background: url(../img/svg/cool-number-doc-3.svg) center / 80px no-repeat $black-bg;
  }
  &:nth-child(4) {
    background: url(../img/svg/cool-number-doc-4.svg) center / 80px no-repeat $black-bg;
  }
  @include tablet {
    width: 300px;
    height: 300px;
  }
  @include desktop {
    font-size: 17px;
    line-height: 18px;
    width: 270px;
    height: 270px;
  }
}
