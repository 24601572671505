.production-numbers {
  position: relative;
}

.production-numbers__title {
  @include margin-bottom-20;
  &-second {
    margin: 0;
    @include margin-bottom-20;
    color: $orange;
  }
  @include breakpoint690 {
    &-second {
      text-align: start;
      &::after {
        content: ":";
      }
    }
  }
  @include tablet {
    &-second {
      font-size: 25px;
    }
  }
  @include desktop {
    &-second {
      font-size: 35px;
    }
  }
}

.production-numbers__content {
  @include margin-bottom-50;
}

.production-numbers__description {
  @include margin-bottom-50;
  &--bg {
    min-height: 250px;
    background-image: url(../img/catalog/production-numbers-1.png);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 110px;
  }
  &-second {
    text-align: center;
    @include margin-bottom-50;
  }
  @include breakpoint690 {
    &--bg {
      min-height: 300px;
      background-size: 80% 170px;
    }
  }
  @include breakpoint1140 {
    min-height: 300px;
    background-size: 50% 210px;
  }
}

.production-numbers__strong {
  display: block;
  @include margin-bottom-20;
}

.production-numbers__item {
  position: relative;
  padding-left: 30px;
  @include margin-bottom-20;
  &:last-child {
    margin-bottom: 0;
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 20px;
    height: 25px;
    background-image: url(../img/svg/doc-item.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }
  &--second {
    padding-left: 0;
    padding-top: 30px;
    text-align: center;
    &::after {
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  @include breakpoint690 {
    &--second {
      padding-left: 30px;
      padding-top: 0;
      text-align: start;
      &::after {
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }
    }
  }
  @include tablet {
    &--second {
      width: 700px;
    }
  }
  @include desktop {
    &--second {
      width: 1000px;
    }
  }
}

.production-numbers__link {
  display: block;
  &:hover {
    color: $orange;
  }
  &:active {
    @include opacity-7;
  }
}
