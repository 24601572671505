.slider__pagination {
	position: absolute;
	left: 50%;
  transform: translate(0, -50%);
  z-index: 1;
}

.swiper-pagination-bullet {
	padding: 5px 10px;
	border-radius: 50%;
	width: 10px;
	height: 10px;
  padding: 0;
	background: $white;
  &:hover {
    background-color: $orange;
    opacity: 1;
  }
  &:active {
    @include opacity-7;
  }
  @include desktop {
    width: 15px;
    height: 15px;
  }
}

.swiper-pagination-bullet-active {
	background: $orange;
}

.custom-prev,
.custom-next {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;
}

.slider__prev,
.slider__next {
  @include breakpoint619-max {
    display: none;
  }
}

.custom-next {
  right: 20px;
}

.custom-prev {
  left: 20px;
}

.arrow  {
  width: 23px;
  height: 30px;
  fill: $white;
  &-rotate {
    transform: rotate(180deg);
  }
  &:hover {
    fill: $orange;
  }
  &:active {
    @include opacity-7;
  }
}

