.services {
  @include margin-bottom-100;
}

.services__title {
  @include margin-bottom-50;
  &-second {
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    min-height: 50px;
    color: $orange;
  }
}

.services__list {
  @include flex-all-center;
  flex-direction: column;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 100px;
  &-second {
    @include flex-h-center;
    flex-direction: column;
    max-width: 250px;
    min-height: 400px;
    margin-bottom: 10px;
  }
  @include breakpoint690 {
    flex-direction: row;
  }
  @include tablet {
    justify-content: space-around;
    column-gap: 0;
  }
}

.services__item {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 310px;
    height: 650px;
    background-image: url(../img/services/phone-frame.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 310px 650px;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 90px;
    height: 20px;
    background-image: url(../img/svg/stars.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60px 20px;
  }
  &-second {
    position: relative;
    padding-left: 25px;
    @include margin-bottom-20;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include tablet {
    &-second {
      font-size: 15px;
      line-height: 18px;
    }
  }
  @include desktop {
    &-second {
      font-size: 17px;
    }
  }
}

.services__content {
  @include flex-v-center;
  flex-direction: column;
  width: 290px;
  min-height: 600px;
  padding: 50px 5px;
  background-color: $black-bg;
  border-radius: 40px;
  box-shadow: 0 20px 20px $black;
}

.services__btn-link {
  @include flex-all-center;
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 50px;
  padding: 10px;
}
