.header {
  @include margin-bottom-50;
  position: relative;
  min-height: 100vh;
  @include bg-cover;
  background-image: url(../img/slides/header-banner.jpg);
  background-position: center;
}

.burger,
.close {
  fill: $white;
  &:hover {
    fill: $orange;
  }
  &:active {
    @include opacity-7;
  }
}

.header__btn {
  position: absolute;
  top: 3%;
  right: 5%;
  z-index: 3;
  width: 50px;
  height: 50px;
  @include flex-all-center;
  &-style {
    border: 1px solid $orange;
    border-radius: 50%;
    background-color: rgba($black, 0.5);
  }
  @include tablet {
    display: none;
  }
}

.burger,
.close {
  width: 20px;
  height: 20px;
}

.close-none {
  display: none;
}

.nav {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 20px;
  width: 250px;
  min-height: 100vh;
  z-index: 2;
  background-color: rgba($black, 0.8);
  background-image: url(../img/svg/stars.svg);
  background-repeat: no-repeat;
  background-position: 50% 98%;
  background-size: 90px 20px;
  @include mobile-all {
    display: none;
  }
  @include breakpoint690 {
    width: 350px;
  }
  @include tablet {
    width: 300px;
  }
  @include breakpoint1140 {
    width: 350px;
  }
  @include desktop {
    width: 410px;
  }
}

.header__logo {
  display: block;
  width: 81px;
  height: 64px;
  margin-left: 15px;
  margin-bottom: 20px;
  @include tablet {
    width: 100%;
    height: 80px;
    margin-left: 0;
  }
}

.nav__item {
  margin-bottom: 10px;
  padding: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background-color: $orange;
  }
  &:active {
    @include opacity-7;
  }
}

.header__slider {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 290px;
  margin: 0;
  @include breakpoint690 {
    width: 400px;
  }
  @include tablet {
    left: 20%;
    transform: translate(-20%, -50%)
  }
  @include breakpoint1140 {
    width: 550px;
  }
  @include desktop {
    width: 655px;
  }
}

.header__content {
  width: 100%;
}

.header__title {
  text-align: start;
  line-height: 30px;
  @include margin-bottom-20;
}

.header__description {
  @include margin-bottom-50;
}
