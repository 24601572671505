.about-us {
  @include margin-bottom-100;
}

.about-us__title {
  @include margin-bottom-50;
}

.about-us__introduction {
  @include margin-bottom-50;
  text-align: center;
}

.about-us__list {
  @include flex-all-center;
  flex-direction: column;
  text-align: center;
  row-gap: 20px;
  margin-bottom: 10px;
  @include breakpoint690 {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }
  @include desktop {
    gap: 50px;
  }
}

.about-us__item {
  width: 250px;
  min-height: 250px;
  border-radius: 10px;
  border: 2px solid $orange;
  background-color: $black-bg;
  padding: 10px;
  @include tablet {
    width: 350px;
    min-height: 350px;
    padding: 20px;
  }
}

.about-us__content {
  @include flex-all-sb;
  flex-direction: column;
  min-height: 220px;
  &--icon-1 {
    @include bg-cover;
    background-image: url(../img/svg/about-us-1.svg);
  }
  &--icon-2 {
    @include bg-cover;
    background-image: url(../img/svg/about-us-2.svg);
  }
  &--icon-3 {
    @include bg-cover;
    background-image: url(../img/svg/about-us-3.svg);
  }
  &--icon-4 {
    @include bg-cover;
    background-image: url(../img/svg/about-us-4.svg);
  }
  &--icon-5 {
    @include bg-cover;
    background-image: url(../img/svg/about-us-5.svg);
  }
  &--icon-6 {
    @include bg-cover;
    background-image: url(../img/svg/about-us-6.svg);
  }

  &--icon-1,
  &--icon-2,
  &--icon-3,
  &--icon-4,
  &--icon-5,
  &--icon-6 {
    background-size: 80px 80px;
  }
  @include tablet {
    min-height: 300px;
    &--icon-1,
    &--icon-2,
    &--icon-3,
    &--icon-4,
    &--icon-5,
    &--icon-6 {
      background-size: 100px 100px;
    }
  }
}
