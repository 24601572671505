@mixin bg-cover {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@mixin flex {
  display: flex;
}

@mixin flex-v-center {
  display: flex;
  align-items: center;
}

@mixin flex-h-center {
  display: flex;
  justify-content: center;
}

@mixin flex-all-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-all-sb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-column-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
