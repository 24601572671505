.reviews {
  @include margin-bottom-100;
}

.reviews__title {
  @include margin-bottom-50;
}

.reviews__slider {
  min-height: 420px;
}

.reviews__wrapper {
  align-items: center;
}

.reviews__content {
  text-align: center;
  @include bg-cover;
  background-color: $black-bg;
  padding: 20px 10px 50px 10px;
  border-radius: 40px;
  border: 2px solid $orange;
  max-width: 350px;
  min-height: 300px;
  margin: auto;
  &::before {
    content: "";
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 74px;
    height: 20px;
    background-image: url(../img/svg/stars.svg);
    background-size: 74px 20px;
    background-position: bottom;
    background-repeat: no-repeat;
  }
  @include tablet {
    min-height: 550px;
  }
}

.reviews__avtors {
  padding-top: 35px;
  @include margin-bottom-20;
  color: $orange;
  &::after {
    content: "";
    position: absolute;
    top: 4%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 30px;
    height: 30px;
    background-image: url(../img/svg/user.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px 30px;
  }
}

.reviews__name {
  display: block;
}

.reviews__city {
  font-size: 12px;
}

.reviews__description {
  @include flex-all-center;
  flex-direction: column;
  min-height: 200px;
  @include tablet {
    min-height: 400px;
  }
}

.reviews__pagination {
  @include breakpoint620 {
    display: none;
  }
}

.reviews__next,
.reviews__prev {
  top: 50%;
  transform: translate(0, -50%);
  @include breakpoint619-max {
    display: none;
  }
}

.reviews__next {
  right: 0;
  @include breakpoint690 {
    right: 10%;
  }
  @include tablet {
    right: 0;
  }
  @include desktop {
    right: 3%;
  }
  @include breakpoint1640 {
    right: 5%;
  }
}

.reviews__prev {
  left: 0;
  @include breakpoint690 {
    left: 10%;
  }
  @include tablet {
    left: 0;
  }
  @include desktop {
    left: 3%;
  }
  @include breakpoint1640 {
    left: 5%;
  }
}
