.services-content__block {
  font-size: 10px;
  line-height: 12px;
}

.services-content__title {
  @include margin-bottom-20;
  @include tablet {
    @include margin-bottom-50;
  }
}

.banner {
  @include margin-bottom-50;
  min-height: 250px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 2px solid $orange;
  border-bottom: 2px solid $orange;
  background-color: $black-bg;
  &-bg-1 {
    background-image: url(../img/services/banner-1.png);
    background-repeat: no-repeat;
    background-position: right 75%;
    background-size: 200px 130px;
  }
  &-bg-2 {
    background-image: url(../img/services/banner-2.png);
    background-repeat: no-repeat;
    background-position: right 75%;
    background-size: 180px 130px;
  }
  &-bg-3 {
    background-image: url(../img/services/banner-3.png);
    background-repeat: no-repeat;
    background-position: right 75%;
    background-size: 180px 130px;
  }
  @include breakpoint490 {
    &-bg-1 {
      background-size: 270px 150px;
    }
    &-bg-2,
    &-bg-3 {
      background-size: 250px 150px;
    }
  }
  @include breakpoint690 {
    font-size: 20px;
    line-height: 20px;
    &-bg-1 {
      background-size: 350px 200px;
    }
    &-bg-2,
    &-bg-3 {
      background-size: 350px 200px;
    }
  }
  @include tablet {
    min-height: 400px;
    &-bg-1 {
      background-size: 430px 250px;
    }
    &-bg-2,
    &-bg-3 {
      background-size: 400px 250px;
    }
  }
  @include breakpoint1140 {
    &-bg-1 {
      background-size: 600px 300px;
    }
    &-bg-2,
    &-bg-3 {
      background-size: 550px 300px;
    }
  }
  @include desktop {
    min-height: 500px;
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 24px;
    line-height: 24px;
    &-bg-1 {
      background-size: 700px 350px;
    }
    &-bg-2,
    &-bg-3 {
      background-size: 600px 300px;
    }
  }
}

.banner__title,
.services-content__slider-title,
.services-content__slider-descr {
  color: $orange;
}

.banner__title {
  margin: 0;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 15px;
  max-width: 260px;
  font-weight: 400;
  @include breakpoint690 {
    @include margin-bottom-20;
    font-size: 24px;
    line-height: 24px;
    max-width: 490px;
  }
  @include desktop {
    font-size: 32px;
    line-height: 32px;
    max-width: 660px;
  }
}

.banner__description {
  margin-bottom: 10px;
  max-width: 230px;
  @include breakpoint690 {
    @include margin-bottom-20;
    max-width: 350px;
  }
  @include desktop {
    max-width: 550px;
  }
}

.banner__list {
  @include margin-bottom-20;
  @include breakpoint690 {
    @include margin-bottom-50;
  }
}

.banner__item {
  position: relative;
  margin-bottom: 10px;
  padding-left: 20px;
  width: 150px;
  &:last-child {
    margin-bottom: 0;
  }
  @include breakpoint690 {
    padding-left: 30px;
    width: 300px;
  }
  @include desktop {
    width: 500px;
    margin-bottom: 15px;
  }
}

.banner__btn {
  max-width: 150px;
  min-height: 40px;
  @include breakpoint690 {
    max-width: 250px;
    min-height: 50px;
  }
  @include desktop {
    max-width: 250px;
    font-size: 20px;
    line-height: 20px;
  }
}
