.contacts__title {
  @include margin-bottom-50;
  &-second {
    margin-bottom: 10px;
  }
}

.contacts__wrapper {
  position: relative;
  border-top: 3px solid $orange;
  border-bottom: 3px solid $orange;
}

.contacts__content {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-color: rgba($black, 0.8);
  z-index: 1;
  padding-top: 50px;
  padding-left: 10px;
  @include breakpoint690 {
    padding-left: 50px;
  }
  @include tablet {
    padding-top: 30px;
    padding-left: 25px;
    display: block;
    width: 50%;
  }
  @include desktop {
    width: 40%;
    padding-left: 40px
  }
}

.contacts__item {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
}

.contacts__tel {
  @include flex;
  flex-direction: column;
  row-gap: 5px;
}

.contacts__link {
  font-size: 18px;
  display: block;
  color: $orange;
  max-width: 240px;
  @include desktop {
    font-size: 20px;
    max-width: 250px;
  }
  &:hover {
    @include opacity-7;
  }
  &:active {
    opacity: 0.3;
  }
}

.contacts__btn-close {
  position: absolute;
  top: 5%;
  right: 5%;
  @include tablet {
    display: none;
  }
}

.map {
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  width: 100%;
  min-height: 400px;
}

.contacts__btn-open {
  @include btn-popup;
  top: 5%;
  right: 5%;
  @include tablet {
    display: none;
  }
}
