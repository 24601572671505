.footer {
  padding: 25px 0;
  background-color: $black-bg;
  background-image: url(../img/svg/stars.svg);
  background-repeat: no-repeat;
  background-size: 60px 15px;
  background-position: 50% 97%;
  @include tablet {
    background-size: 150px 30px;
  }
}

.footer__grid {
  display: grid;
  grid-template-rows: 68px 1fr 1fr 13px;
  grid-template-columns: 1fr 1fr;
  @include breakpoint690 {
    grid-template-rows: 100px 1fr 1fr 15px;
  }
  @include tablet {
    grid-template-rows: 124px 1fr 1fr 20px;
    row-gap: 10px;
  }
}

.footer__logo {
  width: 81px;
  height: 64px;
  @include breakpoint690 {
    width: 150px;
    height: 100px;
  }
  @include tablet {
    width: 203px;
    height: 122px;
  }
}

.footer__schedule-list {
  padding-top: 10px;
  font-size: 8px;
  line-height: 8px;
  grid-row: 2/4;
  grid-column: 1/2;
  @include tablet {
    font-size: 15px;
    line-height: 17px;
  }
  @include desktop {
    font-size: 24px;
    line-height: 27px;
  }
}

.schedule-item-title {
  margin-bottom: 5px
}

.footer__schedule-item {
  margin-bottom: 3px;
  width: 70px;
  &:last-child {
    margin-bottom: 0
  }
  @include tablet {
    width: 130px;
  }
  @include desktop {
    width: 220px;
  }
}

.strong-left {
  float: right;
}

.footer__list {
  @include flex;
  gap: 15px;
  grid-row: 1/2;
  grid-column: 2/3;
  align-self: center;
  justify-self: end;
}

.footer__item {
  @include flex-all-center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 2px solid $orange;
  border-radius: 50%;
  &:hover .social {
    fill: $orange;
  }
  &:active .social {
    @include opacity-7;
  }
  @include breakpoint690 {
    width: 70px;
    height: 70px;
  }
  @include tablet {
    width: 80px;
    height: 80px;
  }
}

.social {
  width: 16px;
  height: 13px;
  fill: $white;
  @include breakpoint690 {
    width: 40px;
    height: 27px;
  }
}

.footer__links {
  grid-row: 2/3;
  grid-column: 2/3;
  justify-self: end;
}

.link-mail {
  position: relative;
  margin-bottom: 10px;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -40%;
    width: 20px;
    height: 15px;
    @include bg-cover;
    background-image: url(../img/svg/mail.svg);
    @include tablet {
      background-size: 25px 20px;
      width: 25px;
      height: 20px;
    }
    @include desktop {
      background-size: 35px 30px;
      width: 35px;
      height: 30px;
    }
  }
}

.footer__tel {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 15%;
    left: -40%;
    width: 20px;
    height: 20px;
    @include bg-cover;
    background-image: url(../img/svg/tel.svg);
    @include tablet {
      width: 25px;
      height: 25px;
    }
    @include desktop {
      width: 35px;
      height: 35px;
    }
  }
}

.footer__link {
  display: block;
  font-size: 10px;
  line-height: 15px;
  &:hover {
    color: $orange;
  }
  &:active {
    color: rgba($orange, 0.8);
  }
  @include breakpoint690 {
    font-size: 12px;
    line-height: 14px;
  }
  @include breakpoint690 {
    font-size: 15px;
    line-height: 18px;
  }
  @include desktop {
    font-size: 24px;
    line-height: 27px;
  }
}

.footer__iframe {
  border: none;
  width: 150px;
  height: 50px;
  grid-row: 3/4;
  grid-column: 2/3;
  justify-self: end;
  align-self: center;
  margin: 5px 0;
  @include desktop {
    transform: scale(1.5) translate(-22px, 0);
  }
}

.footer__copy {
  font-size: 6px;
  grid-row: 4/5;
  grid-column: 2/3;
  justify-self: end;
  @include tablet {
    font-size: 12px;
  }
}

.btn-scroll-top {
  position: fixed;
  top: 94%;
  left: 3%;
  z-index: 2;
  @include flex-all-center;
  flex-direction: column;
  font-size: 6px;
  width: 50px;
  height: 50px;
  color: $white;
  background-color: rgba($black, 0.8);
  border: 2px solid $orange;
  border-radius: 50%;
  &:hover,
  &:hover .scroll {
    color: $orange;
    fill: $orange;
  }
  &:active,
  &:active .scroll {
    opacity: 0.8;
  }
  @include desktop {
    top: 87%;
    left: 2%;
    width: 70px;
    height: 70px;
    font-size: 10px;
  }
}

.scroll {
  width: 21px;
  height: 23px;
  fill: $white;
  @include desktop {
    width: 25px;
    height: 27px;
  }
}
