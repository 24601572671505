@charset "UTF-8";
@font-face {
  font-family: "GG-Wolfram";
  src: url("../fonts/../fonts/GG-Wolfram.woff") format("woff2"), url("../fonts/../fonts/GG-Wolfram.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GG-Wolfram";
  src: url("../fonts/../fonts/GG-Wolfram.woff2") format("woff2"), url("../fonts/../fonts/GG-Wolfram.woff2") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*:hover {
  transition: all 0.3s;
}

*::before,
*::after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  width: 100%;
  object-fit: contain;
}

dl,
dt,
dd {
  margin: 0;
  padding: 0;
}

.page {
  font-family: "GG-Wolfram";
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  background-color: #2e2d2d;
  color: #fff;
  overflow-x: hidden;
}
@media (min-width: 834px) {
  .page {
    font-size: 20px;
    line-height: 24px;
  }
}

.container {
  max-width: 310px;
  margin: 0 auto;
  padding: 0 5px;
}
@media (min-width: 390px) {
  .container {
    max-width: 380px;
  }
}
@media (min-width: 490px) {
  .container {
    max-width: 470px;
    padding: 0 10px;
  }
}
@media (min-width: 690px) {
  .container {
    max-width: 670px;
  }
}
@media (min-width: 790px) {
  .container {
    max-width: 770px;
  }
}
@media (min-width: 834px) {
  .container {
    max-width: 814px;
  }
}
@media (min-width: 1140px) {
  .container {
    max-width: 1040px;
    padding: 0 50px;
  }
}
@media (min-width: 1340px) {
  .container {
    max-width: 1240px;
  }
}
@media (min-width: 1440px) {
  .container {
    max-width: 1340px;
  }
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.btn {
  border: 2px solid #f08951;
  border-radius: 30px;
  background-color: transparent;
  color: #fff;
  padding: 0 10px;
}
.btn:hover, .btn:hover .link-icon {
  color: #f08951;
  fill: #f08951;
}
.btn:active {
  opacity: 0.7;
}

.btn-link {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  min-height: 50px;
}
@media (min-width: 1440px) {
  .btn-link {
    max-width: 200px;
  }
}

.link-icon {
  width: 10px;
  height: 10px;
  fill: #fff;
  margin-left: 5px;
}
@media (min-width: 834px) {
  .link-icon {
    width: 15px;
    height: 15px;
  }
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}

.title {
  margin: 0;
  font-size: 25px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 1px;
}
@media (min-width: 834px) {
  .title {
    font-size: 35px;
    line-height: 48px;
  }
}
@media (min-width: 1440px) {
  .title {
    font-size: 48px;
    line-height: 57px;
  }
}
.title-second {
  margin: 0;
  font-size: 20px;
  line-height: 25px;
}
@media (min-width: 834px) {
  .title-second {
    font-size: 25px;
    line-height: 30px;
  }
}

.description {
  margin: 0;
}

.strong {
  color: #f08951;
}

.tick::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 15px;
  height: 15px;
  background-image: url(../img/svg/sprite/tick.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px 15px;
}

.uppercase {
  text-transform: uppercase;
}

.animation-left {
  animation: ainimation-left 2s;
}

@keyframes ainimation-left {
  0% {
    transform: translate(-100%);
  }
}
.animation-top {
  animation: ainimation-top 2s;
}

@keyframes ainimation-top {
  0% {
    background-color: rgba(0, 0, 0, 0);
    transform: translate(0, -100%);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.8);
  }
}
.ainimation-popup {
  animation: ainimation-popup 2s;
}

@keyframes ainimation-popup {
  0% {
    transform: translate(100%, -50%);
  }
}
.ainimation-product-cart {
  animation: ainimation-product-cart 1s;
}

@keyframes ainimation-product-cart {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes ainimation-scale {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.header {
  margin-bottom: 50px;
  position: relative;
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../img/slides/header-banner.jpg);
  background-position: center;
}

.burger,
.close {
  fill: #fff;
}
.burger:hover,
.close:hover {
  fill: #f08951;
}
.burger:active,
.close:active {
  opacity: 0.7;
}

.header__btn {
  position: absolute;
  top: 3%;
  right: 5%;
  z-index: 3;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__btn-style {
  border: 1px solid #f08951;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
}
@media (min-width: 834px) {
  .header__btn {
    display: none;
  }
}

.burger,
.close {
  width: 20px;
  height: 20px;
}

.close-none {
  display: none;
}

.nav {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 20px;
  width: 250px;
  min-height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.8);
  background-image: url(../img/svg/stars.svg);
  background-repeat: no-repeat;
  background-position: 50% 98%;
  background-size: 90px 20px;
}
@media (max-width: 833px) {
  .nav {
    display: none;
  }
}
@media (min-width: 690px) {
  .nav {
    width: 350px;
  }
}
@media (min-width: 834px) {
  .nav {
    width: 300px;
  }
}
@media (min-width: 1140px) {
  .nav {
    width: 350px;
  }
}
@media (min-width: 1440px) {
  .nav {
    width: 410px;
  }
}

.header__logo {
  display: block;
  width: 81px;
  height: 64px;
  margin-left: 15px;
  margin-bottom: 20px;
}
@media (min-width: 834px) {
  .header__logo {
    width: 100%;
    height: 80px;
    margin-left: 0;
  }
}

.nav__item {
  margin-bottom: 10px;
  padding: 10px;
}
.nav__item:last-child {
  margin-bottom: 0;
}
.nav__item:hover {
  background-color: #f08951;
}
.nav__item:active {
  opacity: 0.7;
}

.header__slider {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 290px;
  margin: 0;
}
@media (min-width: 690px) {
  .header__slider {
    width: 400px;
  }
}
@media (min-width: 834px) {
  .header__slider {
    left: 20%;
    transform: translate(-20%, -50%);
  }
}
@media (min-width: 1140px) {
  .header__slider {
    width: 550px;
  }
}
@media (min-width: 1440px) {
  .header__slider {
    width: 655px;
  }
}

.header__content {
  width: 100%;
}

.header__title {
  text-align: start;
  line-height: 30px;
  margin-bottom: 20px;
}

.header__description {
  margin-bottom: 50px;
}

.slider__pagination {
  position: absolute;
  left: 50%;
  transform: translate(0, -50%);
  z-index: 1;
}

.swiper-pagination-bullet {
  padding: 5px 10px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding: 0;
  background: #fff;
}
.swiper-pagination-bullet:hover {
  background-color: #f08951;
  opacity: 1;
}
.swiper-pagination-bullet:active {
  opacity: 0.7;
}
@media (min-width: 1440px) {
  .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
  }
}

.swiper-pagination-bullet-active {
  background: #f08951;
}

.custom-prev,
.custom-next {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;
}

@media (max-width: 619px) {
  .slider__prev,
.slider__next {
    display: none;
  }
}

.custom-next {
  right: 20px;
}

.custom-prev {
  left: 20px;
}

.arrow {
  width: 23px;
  height: 30px;
  fill: #fff;
}
.arrow-rotate {
  transform: rotate(180deg);
}
.arrow:hover {
  fill: #f08951;
}
.arrow:active {
  opacity: 0.7;
}

.about-us {
  margin-bottom: 100px;
}

.about-us__title {
  margin-bottom: 50px;
}

.about-us__introduction {
  margin-bottom: 50px;
  text-align: center;
}

.about-us__list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  row-gap: 20px;
  margin-bottom: 10px;
}
@media (min-width: 690px) {
  .about-us__list {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }
}
@media (min-width: 1440px) {
  .about-us__list {
    gap: 50px;
  }
}

.about-us__item {
  width: 250px;
  min-height: 250px;
  border-radius: 10px;
  border: 2px solid #f08951;
  background-color: #1e1e1e;
  padding: 10px;
}
@media (min-width: 834px) {
  .about-us__item {
    width: 350px;
    min-height: 350px;
    padding: 20px;
  }
}

.about-us__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  min-height: 220px;
}
.about-us__content--icon-1 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../img/svg/about-us-1.svg);
}
.about-us__content--icon-2 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../img/svg/about-us-2.svg);
}
.about-us__content--icon-3 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../img/svg/about-us-3.svg);
}
.about-us__content--icon-4 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../img/svg/about-us-4.svg);
}
.about-us__content--icon-5 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../img/svg/about-us-5.svg);
}
.about-us__content--icon-6 {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../img/svg/about-us-6.svg);
}
.about-us__content--icon-1, .about-us__content--icon-2, .about-us__content--icon-3, .about-us__content--icon-4, .about-us__content--icon-5, .about-us__content--icon-6 {
  background-size: 80px 80px;
}
@media (min-width: 834px) {
  .about-us__content {
    min-height: 300px;
  }
  .about-us__content--icon-1, .about-us__content--icon-2, .about-us__content--icon-3, .about-us__content--icon-4, .about-us__content--icon-5, .about-us__content--icon-6 {
    background-size: 100px 100px;
  }
}

.services {
  margin-bottom: 100px;
}

.services__title {
  margin-bottom: 50px;
}
.services__title-second {
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  min-height: 50px;
  color: #f08951;
}

.services__list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 100px;
}
.services__list-second {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 250px;
  min-height: 400px;
  margin-bottom: 10px;
}
@media (min-width: 690px) {
  .services__list {
    flex-direction: row;
  }
}
@media (min-width: 834px) {
  .services__list {
    justify-content: space-around;
    column-gap: 0;
  }
}

.services__item {
  position: relative;
}
.services__item::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 310px;
  height: 650px;
  background-image: url(../img/services/phone-frame.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: 310px 650px;
}
.services__item::after {
  content: "";
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 90px;
  height: 20px;
  background-image: url(../img/svg/stars.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60px 20px;
}
.services__item-second {
  position: relative;
  padding-left: 25px;
  margin-bottom: 20px;
}
.services__item-second:last-child {
  margin-bottom: 0;
}
@media (min-width: 834px) {
  .services__item-second {
    font-size: 15px;
    line-height: 18px;
  }
}
@media (min-width: 1440px) {
  .services__item-second {
    font-size: 17px;
  }
}

.services__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 290px;
  min-height: 600px;
  padding: 50px 5px;
  background-color: #1e1e1e;
  border-radius: 40px;
  box-shadow: 0 20px 20px #000;
}

.services__btn-link {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 50px;
  padding: 10px;
}

.reviews {
  margin-bottom: 100px;
}

.reviews__title {
  margin-bottom: 50px;
}

.reviews__slider {
  min-height: 420px;
}

.reviews__wrapper {
  align-items: center;
}

.reviews__content {
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #1e1e1e;
  padding: 20px 10px 50px 10px;
  border-radius: 40px;
  border: 2px solid #f08951;
  max-width: 350px;
  min-height: 300px;
  margin: auto;
}
.reviews__content::before {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 74px;
  height: 20px;
  background-image: url(../img/svg/stars.svg);
  background-size: 74px 20px;
  background-position: bottom;
  background-repeat: no-repeat;
}
@media (min-width: 834px) {
  .reviews__content {
    min-height: 550px;
  }
}

.reviews__avtors {
  padding-top: 35px;
  margin-bottom: 20px;
  color: #f08951;
}
.reviews__avtors::after {
  content: "";
  position: absolute;
  top: 4%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 30px;
  height: 30px;
  background-image: url(../img/svg/user.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px 30px;
}

.reviews__name {
  display: block;
}

.reviews__city {
  font-size: 12px;
}

.reviews__description {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 200px;
}
@media (min-width: 834px) {
  .reviews__description {
    min-height: 400px;
  }
}

@media (min-width: 620px) {
  .reviews__pagination {
    display: none;
  }
}

.reviews__next,
.reviews__prev {
  top: 50%;
  transform: translate(0, -50%);
}
@media (max-width: 619px) {
  .reviews__next,
.reviews__prev {
    display: none;
  }
}

.reviews__next {
  right: 0;
}
@media (min-width: 690px) {
  .reviews__next {
    right: 10%;
  }
}
@media (min-width: 834px) {
  .reviews__next {
    right: 0;
  }
}
@media (min-width: 1440px) {
  .reviews__next {
    right: 3%;
  }
}
@media (min-width: 1640px) {
  .reviews__next {
    right: 5%;
  }
}

.reviews__prev {
  left: 0;
}
@media (min-width: 690px) {
  .reviews__prev {
    left: 10%;
  }
}
@media (min-width: 834px) {
  .reviews__prev {
    left: 0;
  }
}
@media (min-width: 1440px) {
  .reviews__prev {
    left: 3%;
  }
}
@media (min-width: 1640px) {
  .reviews__prev {
    left: 5%;
  }
}

.photo-gallery {
  margin-bottom: 50px;
  position: relative;
}

.photo-gallery__title {
  margin-bottom: 50px;
}

.photo-gallery__content {
  background-color: #1e1e1e;
  padding: 25px 0;
}

.photo-gallery__list {
  padding: 0;
}

.photo-gallery__item {
  width: 250px;
  height: 250px;
}
@media (min-width: 834px) {
  .photo-gallery__item {
    height: 300px;
  }
}
@media (min-width: 1440px) {
  .photo-gallery__item {
    height: 350px;
  }
}

.photo-gallery__img {
  display: block;
  height: 100%;
  object-fit: fill;
}

.photo-gallery__btn--prev {
  left: 1%;
}
.photo-gallery__btn--next {
  right: 1%;
}

.contacts__title {
  margin-bottom: 50px;
}
.contacts__title-second {
  margin-bottom: 10px;
}

.contacts__wrapper {
  position: relative;
  border-top: 3px solid #f08951;
  border-bottom: 3px solid #f08951;
}

.contacts__content {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  padding-top: 50px;
  padding-left: 10px;
}
@media (min-width: 690px) {
  .contacts__content {
    padding-left: 50px;
  }
}
@media (min-width: 834px) {
  .contacts__content {
    padding-top: 30px;
    padding-left: 25px;
    display: block;
    width: 50%;
  }
}
@media (min-width: 1440px) {
  .contacts__content {
    width: 40%;
    padding-left: 40px;
  }
}

.contacts__item {
  margin-bottom: 30px;
}
.contacts__item:last-child {
  margin-bottom: 0;
}

.contacts__tel {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.contacts__link {
  font-size: 18px;
  display: block;
  color: #f08951;
  max-width: 240px;
}
@media (min-width: 1440px) {
  .contacts__link {
    font-size: 20px;
    max-width: 250px;
  }
}
.contacts__link:hover {
  opacity: 0.7;
}
.contacts__link:active {
  opacity: 0.3;
}

.contacts__btn-close {
  position: absolute;
  top: 5%;
  right: 5%;
}
@media (min-width: 834px) {
  .contacts__btn-close {
    display: none;
  }
}

.map {
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  width: 100%;
  min-height: 400px;
}

.contacts__btn-open {
  position: absolute;
  font-size: 10px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  border: 2px solid #f08951;
  width: 70px;
  height: 70px;
  padding: 5px;
  background-image: url(../img/svg/stars.svg);
  background-repeat: no-repeat;
  background-position: 50% 80%;
  background-size: 38px 11px;
  top: 5%;
  right: 5%;
}
.contacts__btn-open:hover {
  color: #f08951;
}
.contacts__btn-open:active {
  opacity: 0.7;
}
@media (min-width: 834px) {
  .contacts__btn-open {
    display: none;
  }
}

.footer {
  padding: 25px 0;
  background-color: #1e1e1e;
  background-image: url(../img/svg/stars.svg);
  background-repeat: no-repeat;
  background-size: 60px 15px;
  background-position: 50% 97%;
}
@media (min-width: 834px) {
  .footer {
    background-size: 150px 30px;
  }
}

.footer__grid {
  display: grid;
  grid-template-rows: 68px 1fr 1fr 13px;
  grid-template-columns: 1fr 1fr;
}
@media (min-width: 690px) {
  .footer__grid {
    grid-template-rows: 100px 1fr 1fr 15px;
  }
}
@media (min-width: 834px) {
  .footer__grid {
    grid-template-rows: 124px 1fr 1fr 20px;
    row-gap: 10px;
  }
}

.footer__logo {
  width: 81px;
  height: 64px;
}
@media (min-width: 690px) {
  .footer__logo {
    width: 150px;
    height: 100px;
  }
}
@media (min-width: 834px) {
  .footer__logo {
    width: 203px;
    height: 122px;
  }
}

.footer__schedule-list {
  padding-top: 10px;
  font-size: 8px;
  line-height: 8px;
  grid-row: 2/4;
  grid-column: 1/2;
}
@media (min-width: 834px) {
  .footer__schedule-list {
    font-size: 15px;
    line-height: 17px;
  }
}
@media (min-width: 1440px) {
  .footer__schedule-list {
    font-size: 24px;
    line-height: 27px;
  }
}

.schedule-item-title {
  margin-bottom: 5px;
}

.footer__schedule-item {
  margin-bottom: 3px;
  width: 70px;
}
.footer__schedule-item:last-child {
  margin-bottom: 0;
}
@media (min-width: 834px) {
  .footer__schedule-item {
    width: 130px;
  }
}
@media (min-width: 1440px) {
  .footer__schedule-item {
    width: 220px;
  }
}

.strong-left {
  float: right;
}

.footer__list {
  display: flex;
  gap: 15px;
  grid-row: 1/2;
  grid-column: 2/3;
  align-self: center;
  justify-self: end;
}

.footer__item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 2px solid #f08951;
  border-radius: 50%;
}
.footer__item:hover .social {
  fill: #f08951;
}
.footer__item:active .social {
  opacity: 0.7;
}
@media (min-width: 690px) {
  .footer__item {
    width: 70px;
    height: 70px;
  }
}
@media (min-width: 834px) {
  .footer__item {
    width: 80px;
    height: 80px;
  }
}

.social {
  width: 16px;
  height: 13px;
  fill: #fff;
}
@media (min-width: 690px) {
  .social {
    width: 40px;
    height: 27px;
  }
}

.footer__links {
  grid-row: 2/3;
  grid-column: 2/3;
  justify-self: end;
}

.link-mail {
  position: relative;
  margin-bottom: 10px;
}
.link-mail::after {
  content: "";
  position: absolute;
  top: 0;
  left: -40%;
  width: 20px;
  height: 15px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../img/svg/mail.svg);
}
@media (min-width: 834px) {
  .link-mail::after {
    background-size: 25px 20px;
    width: 25px;
    height: 20px;
  }
}
@media (min-width: 1440px) {
  .link-mail::after {
    background-size: 35px 30px;
    width: 35px;
    height: 30px;
  }
}

.footer__tel {
  position: relative;
}
.footer__tel::after {
  content: "";
  position: absolute;
  top: 15%;
  left: -40%;
  width: 20px;
  height: 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../img/svg/tel.svg);
}
@media (min-width: 834px) {
  .footer__tel::after {
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 1440px) {
  .footer__tel::after {
    width: 35px;
    height: 35px;
  }
}

.footer__link {
  display: block;
  font-size: 10px;
  line-height: 15px;
}
.footer__link:hover {
  color: #f08951;
}
.footer__link:active {
  color: rgba(240, 137, 81, 0.8);
}
@media (min-width: 690px) {
  .footer__link {
    font-size: 12px;
    line-height: 14px;
  }
}
@media (min-width: 690px) {
  .footer__link {
    font-size: 15px;
    line-height: 18px;
  }
}
@media (min-width: 1440px) {
  .footer__link {
    font-size: 24px;
    line-height: 27px;
  }
}

.footer__iframe {
  border: none;
  width: 150px;
  height: 50px;
  grid-row: 3/4;
  grid-column: 2/3;
  justify-self: end;
  align-self: center;
  margin: 5px 0;
}
@media (min-width: 1440px) {
  .footer__iframe {
    transform: scale(1.5) translate(-22px, 0);
  }
}

.footer__copy {
  font-size: 6px;
  grid-row: 4/5;
  grid-column: 2/3;
  justify-self: end;
}
@media (min-width: 834px) {
  .footer__copy {
    font-size: 12px;
  }
}

.btn-scroll-top {
  position: fixed;
  top: 94%;
  left: 3%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 6px;
  width: 50px;
  height: 50px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  border: 2px solid #f08951;
  border-radius: 50%;
}
.btn-scroll-top:hover, .btn-scroll-top:hover .scroll {
  color: #f08951;
  fill: #f08951;
}
.btn-scroll-top:active, .btn-scroll-top:active .scroll {
  opacity: 0.8;
}
@media (min-width: 1440px) {
  .btn-scroll-top {
    top: 87%;
    left: 2%;
    width: 70px;
    height: 70px;
    font-size: 10px;
  }
}

.scroll {
  width: 21px;
  height: 23px;
  fill: #fff;
}
@media (min-width: 1440px) {
  .scroll {
    width: 25px;
    height: 27px;
  }
}

.production-numbers {
  position: relative;
}

.production-numbers__title {
  margin-bottom: 20px;
}
.production-numbers__title-second {
  margin: 0;
  margin-bottom: 20px;
  color: #f08951;
}
@media (min-width: 690px) {
  .production-numbers__title-second {
    text-align: start;
  }
  .production-numbers__title-second::after {
    content: ":";
  }
}
@media (min-width: 834px) {
  .production-numbers__title-second {
    font-size: 25px;
  }
}
@media (min-width: 1440px) {
  .production-numbers__title-second {
    font-size: 35px;
  }
}

.production-numbers__content {
  margin-bottom: 50px;
}

.production-numbers__description {
  margin-bottom: 50px;
}
.production-numbers__description--bg {
  min-height: 250px;
  background-image: url(../img/catalog/production-numbers-1.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100% 110px;
}
.production-numbers__description-second {
  text-align: center;
  margin-bottom: 50px;
}
@media (min-width: 690px) {
  .production-numbers__description--bg {
    min-height: 300px;
    background-size: 80% 170px;
  }
}
@media (min-width: 1140px) {
  .production-numbers__description {
    min-height: 300px;
    background-size: 50% 210px;
  }
}

.production-numbers__strong {
  display: block;
  margin-bottom: 20px;
}

.production-numbers__item {
  position: relative;
  padding-left: 30px;
  margin-bottom: 20px;
}
.production-numbers__item:last-child {
  margin-bottom: 0;
}
.production-numbers__item::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 20px;
  height: 25px;
  background-image: url(../img/svg/doc-item.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
.production-numbers__item--second {
  padding-left: 0;
  padding-top: 30px;
  text-align: center;
}
.production-numbers__item--second::after {
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
@media (min-width: 690px) {
  .production-numbers__item--second {
    padding-left: 30px;
    padding-top: 0;
    text-align: start;
  }
  .production-numbers__item--second::after {
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }
}
@media (min-width: 834px) {
  .production-numbers__item--second {
    width: 700px;
  }
}
@media (min-width: 1440px) {
  .production-numbers__item--second {
    width: 1000px;
  }
}

.production-numbers__link {
  display: block;
}
.production-numbers__link:hover {
  color: #f08951;
}
.production-numbers__link:active {
  opacity: 0.7;
}

.production-numbers__slider {
  position: relative;
  overflow-x: hidden;
  margin-bottom: 50px;
  overflow-y: hidden;
}

.production-numbers__slide {
  position: relative;
  width: 100%;
  min-height: 600px;
  padding-top: 50px;
  padding-bottom: 20px;
}
.production-numbers__slide:nth-child(1) {
  background: linear-gradient(352.28deg, #0F2027 14.25%, #203A43 50.36%, #2C5364 86.76%);
}
.production-numbers__slide:nth-child(2) {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../img/slides/slide-4-mob.jpg);
}
@media (min-width: 490px) {
  .production-numbers__slide:nth-child(2) {
    background-image: url(../img/slides/slide-4-tab.jpg);
  }
}
@media (min-width: 1140px) {
  .production-numbers__slide:nth-child(2) {
    background-image: url(../img/slides/slide-4.jpg);
  }
}
.production-numbers__slide:nth-child(3) {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../img/slides/slide-5-mob.jpg);
}
@media (min-width: 490px) {
  .production-numbers__slide:nth-child(3) {
    background-image: url(../img/slides/slide-5-tab.jpg);
  }
}
@media (min-width: 1140px) {
  .production-numbers__slide:nth-child(3) {
    background-image: url(../img/slides/slide-5.jpg);
  }
}
.production-numbers__slide:nth-child(4) {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../img/slides/slide-6-mob.jpg);
}
@media (min-width: 490px) {
  .production-numbers__slide:nth-child(4) {
    background-image: url(../img/slides/slide-6-tab.jpg);
  }
}
@media (min-width: 1140px) {
  .production-numbers__slide:nth-child(4) {
    background-image: url(../img/slides/slide-6.jpg);
  }
}
@media (min-width: 690px) {
  .production-numbers__slide {
    padding-top: 150px;
  }
  .production-numbers__slide:nth-child(1) {
    padding-top: 70px;
  }
}
@media (min-width: 834px) {
  .production-numbers__slide {
    padding-top: 70px;
  }
}

.production-numbers__slide-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.production-numbers__slide-content--map::after {
  content: "";
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -55%) rotate(-30deg);
  width: 290px;
  height: 110px;
  background-image: url(../img/svg/slide-map.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: -1;
}
@media (min-width: 490px) {
  .production-numbers__slide-content--map::after {
    width: 400px;
    height: 250px;
    top: 50%;
    left: 70%;
    transform: translate(-70%, -50%) rotate(-30deg);
  }
}
@media (min-width: 690px) {
  .production-numbers__slide-content--map::after {
    width: 500px;
    height: 350px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media (min-width: 834px) {
  .production-numbers__slide-content--map::after {
    width: 550px;
    height: 450px;
    top: 70%;
    transform: translate(-50%, -70%);
  }
}
@media (min-width: 1440px) {
  .production-numbers__slide-content--map::after {
    width: 800px;
    height: 500px;
    top: 60%;
    left: 80%;
    transform: translate(-80%, -60%) rotate(-10deg);
  }
}
.production-numbers__slide-content--center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (min-width: 490px) {
  .production-numbers__slide-content {
    text-align: start;
    justify-content: start;
    align-items: start;
    padding-left: 30px;
  }
  .production-numbers__slide-content--center {
    left: 20%;
    transform: translate(-20%, -50%);
  }
}

.production-numbers__slide-title,
.production-numbers__slide-description {
  font-size: 24px;
  line-height: 24px;
}
@media (min-width: 834px) {
  .production-numbers__slide-title,
.production-numbers__slide-description {
    font-size: 32px;
    line-height: 32px;
  }
}

.production-numbers__slide-title {
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #f08951;
}
@media (min-width: 490px) {
  .production-numbers__slide-title {
    text-align: start;
  }
}
@media (min-width: 834px) {
  .production-numbers__slide-title {
    max-width: 600px;
  }
}
@media (min-width: 1440px) {
  .production-numbers__slide-title {
    max-width: 750px;
    font-size: 40px;
    line-height: 40px;
  }
}

.production-numbers__slide-description {
  margin-bottom: 50px;
  max-width: 300px;
}
@media (min-width: 690px) {
  .production-numbers__slide-description {
    max-width: 500px;
  }
}
@media (min-width: 834px) {
  .production-numbers__slide-description {
    max-width: 650px;
  }
}
@media (min-width: 1440px) {
  .production-numbers__slide-description {
    max-width: 750px;
  }
}

.production-numbers__slide-list {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 20px;
  width: 100%;
}
@media (min-width: 690px) {
  .production-numbers__slide-list {
    align-items: end;
  }
}
@media (min-width: 834px) {
  .production-numbers__slide-list {
    column-gap: 40px;
  }
}
@media (min-width: 1440px) {
  .production-numbers__slide-list {
    left: 60%;
    column-gap: 50px;
  }
}

@media (min-width: 690px) {
  .production-numbers__slide-item:nth-child(1) {
    order: 1;
  }
  .production-numbers__slide-item:nth-child(2) {
    order: 2;
  }
  .production-numbers__slide-item:nth-child(3) {
    order: 0;
  }
}

.production-numbers__slide-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  min-height: 50px;
}
@media (min-width: 834px) {
  .production-numbers__slide-btn {
    width: 260px;
  }
}

.production-numbers__prev {
  left: 2%;
}

.production-numbers__next {
  right: 2%;
}

.certificate-popup {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  min-height: 600px;
  z-index: 1;
  background-image: url(../img/catalog/phone-frame.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: 300px 600px;
}
@media (min-width: 834px) {
  .certificate-popup {
    left: 70%;
    transform: translate(-30%, -50%);
  }
}
@media (min-width: 1440px) {
  .certificate-popup {
    left: 75%;
    transform: translate(-25%, -50%);
  }
}

.certificate-popup__flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.certificate-popup__img {
  width: 230px;
  height: 350px;
}

.certificate-popup__btn {
  width: 250px;
  min-height: 50px;
}

.products-card {
  margin-bottom: 50px;
}

.products-card__list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
}

.products-card__item {
  position: relative;
  display: grid;
  grid-template-rows: 50px 150px 50px 50px;
  grid-template-columns: 1fr;
  row-gap: 15px;
  max-width: 300px;
  text-align: center;
  border: 3px solid #f08951;
  border-radius: 40px;
  background-color: #1e1e1e;
  background-image: url(../img/svg/stars.svg);
  background-repeat: no-repeat;
  background-position: 50% 2%;
  background-size: 74px 20px;
  padding: 40px 20px 20px 20px;
}

.products-card__title,
.products-card__prise-list {
  font-size: 20px;
  line-height: 20px;
}

.products-card__title {
  margin: 0;
}

.products-card__img {
  width: 250px;
  height: 150px;
  object-fit: cover;
}

.products-card__tagging {
  position: absolute;
  top: 67%;
  left: 50%;
  transform: translate(-50%, -35%);
  width: 200px;
  font-size: 10px;
  line-height: 10px;
}
.products-card__tagging::before {
  content: "✆";
  display: inline-block;
  font-size: 15px;
  color: #f08951;
  padding-right: 5px;
  animation: ainimation-scale 2s infinite;
}
@media (min-width: 834px) {
  .products-card__tagging {
    width: 250px;
  }
}

.products-card__prise-list {
  justify-self: center;
  align-self: end;
}

.products-card__prise-wrapper {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}
.products-card__prise-wrapper:last-child {
  margin-bottom: 0;
}

.products-card__prise-descr {
  color: #f08951;
  margin-left: 10px;
}

.products-card__btn {
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 250px;
  min-height: 50px;
  line-height: 17px;
  background: linear-gradient(91.93deg, #313234 4.62%, #292929 47.9%, #282828 95.35%);
}
.products-card__btn span {
  font-size: 10px;
  color: #f08951;
}

.products-card__tip {
  position: absolute;
  font-size: 10px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  border: 2px solid #f08951;
  width: 70px;
  height: 70px;
  padding: 5px;
  background-image: url(../img/svg/stars.svg);
  background-repeat: no-repeat;
  background-position: 50% 80%;
  background-size: 38px 11px;
  top: 18%;
  left: 3%;
  text-transform: uppercase;
  transform: rotate(-20deg);
}
.products-card__tip:hover {
  color: #f08951;
}
.products-card__tip:active {
  opacity: 0.7;
}

.products-card__popup {
  display: none;
  position: absolute;
  top: 40.7%;
  left: 50%;
  transform: translate(-50%, -40%);
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 13px;
  line-height: 15px;
  width: 251px;
  min-height: 150px;
}
.products-card__popup-text {
  padding: 0 10px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.products-card__btn-close {
  position: absolute;
  top: 2%;
  right: 2%;
}

.services-introduction {
  margin-bottom: 50px;
}
@media (min-width: 834px) {
  .services-introduction {
    margin-bottom: 100px;
  }
}

.services-introduction__title {
  margin-bottom: 20px;
}

.services-introduction__content {
  margin-bottom: 50px;
  text-align: center;
}

.services-introduction__description {
  margin-bottom: 10px;
}
.services-introduction__description:last-child {
  margin-bottom: 0;
}

.services-introduction__list {
  counter-reset: list-numbering;
  margin-bottom: 20px;
}
.services-introduction__list:last-child {
  margin-bottom: 0;
}
.services-introduction__list--reverse {
  counter-reset: none;
}
@media (min-width: 834px) {
  .services-introduction__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .services-introduction__list--reverse {
    flex-direction: row-reverse;
    padding-top: 20px;
  }
}
@media (min-width: 1440px) {
  .services-introduction__list {
    column-gap: 80px;
  }
}

.services-introduction__item {
  position: relative;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  max-width: 240px;
  min-height: 30px;
  padding-left: 40px;
  counter-increment: list-numbering;
}
.services-introduction__item:last-child {
  margin-bottom: 0;
}
.services-introduction__item::before {
  content: counter(list-numbering);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  border: 2px solid #f08951;
  width: 30px;
  height: 30px;
  background-color: #1e1e1e;
}
@media (min-width: 490px) {
  .services-introduction__item {
    max-width: 400px;
  }
}
@media (min-width: 834px) {
  .services-introduction__item {
    padding-left: 0;
    width: 190px;
    min-height: 100px;
    align-items: end;
    justify-content: center;
    margin-bottom: 0;
  }
  .services-introduction__item::before {
    font-size: 20px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 50px;
    height: 50px;
  }
  .services-introduction__item--traces::after {
    content: "";
    position: absolute;
    top: 15%;
    left: 75%;
    width: 100px;
    height: 17px;
    background-image: url(../img/svg/traces.svg);
    background-repeat: no-repeat;
    background-size: 100px 17px;
  }
  .services-introduction__item--traces-reverse::after {
    transform: rotate(180deg);
  }
}
@media (min-width: 1440px) {
  .services-introduction__item {
    width: 250px;
    min-height: 140px;
  }
  .services-introduction__item::before {
    font-size: 24px;
    width: 80px;
    height: 80px;
  }
  .services-introduction__item--traces::after {
    top: 15%;
    left: 75%;
    width: 200px;
    height: 40px;
    background-size: 200px 40px;
  }
}

@media (min-width: 834px) {
  .services-introduction__item-content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    min-height: 40px;
  }
}
@media (min-width: 1440px) {
  .services-introduction__item-content {
    font-size: 20px;
    line-height: 20px;
  }
}

.services-content__block {
  font-size: 10px;
  line-height: 12px;
}

.services-content__title {
  margin-bottom: 20px;
}
@media (min-width: 834px) {
  .services-content__title {
    margin-bottom: 50px;
  }
}

.banner {
  margin-bottom: 50px;
  min-height: 250px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 2px solid #f08951;
  border-bottom: 2px solid #f08951;
  background-color: #1e1e1e;
}
.banner-bg-1 {
  background-image: url(../img/services/banner-1.png);
  background-repeat: no-repeat;
  background-position: right 75%;
  background-size: 200px 130px;
}
.banner-bg-2 {
  background-image: url(../img/services/banner-2.png);
  background-repeat: no-repeat;
  background-position: right 75%;
  background-size: 180px 130px;
}
.banner-bg-3 {
  background-image: url(../img/services/banner-3.png);
  background-repeat: no-repeat;
  background-position: right 75%;
  background-size: 180px 130px;
}
@media (min-width: 490px) {
  .banner-bg-1 {
    background-size: 270px 150px;
  }
  .banner-bg-2, .banner-bg-3 {
    background-size: 250px 150px;
  }
}
@media (min-width: 690px) {
  .banner {
    font-size: 20px;
    line-height: 20px;
  }
  .banner-bg-1 {
    background-size: 350px 200px;
  }
  .banner-bg-2, .banner-bg-3 {
    background-size: 350px 200px;
  }
}
@media (min-width: 834px) {
  .banner {
    min-height: 400px;
  }
  .banner-bg-1 {
    background-size: 430px 250px;
  }
  .banner-bg-2, .banner-bg-3 {
    background-size: 400px 250px;
  }
}
@media (min-width: 1140px) {
  .banner-bg-1 {
    background-size: 600px 300px;
  }
  .banner-bg-2, .banner-bg-3 {
    background-size: 550px 300px;
  }
}
@media (min-width: 1440px) {
  .banner {
    min-height: 500px;
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 24px;
    line-height: 24px;
  }
  .banner-bg-1 {
    background-size: 700px 350px;
  }
  .banner-bg-2, .banner-bg-3 {
    background-size: 600px 300px;
  }
}

.banner__title,
.services-content__slider-title,
.services-content__slider-descr {
  color: #f08951;
}

.banner__title {
  margin: 0;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 15px;
  max-width: 260px;
  font-weight: 400;
}
@media (min-width: 690px) {
  .banner__title {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 24px;
    max-width: 490px;
  }
}
@media (min-width: 1440px) {
  .banner__title {
    font-size: 32px;
    line-height: 32px;
    max-width: 660px;
  }
}

.banner__description {
  margin-bottom: 10px;
  max-width: 230px;
}
@media (min-width: 690px) {
  .banner__description {
    margin-bottom: 20px;
    max-width: 350px;
  }
}
@media (min-width: 1440px) {
  .banner__description {
    max-width: 550px;
  }
}

.banner__list {
  margin-bottom: 20px;
}
@media (min-width: 690px) {
  .banner__list {
    margin-bottom: 50px;
  }
}

.banner__item {
  position: relative;
  margin-bottom: 10px;
  padding-left: 20px;
  width: 150px;
}
.banner__item:last-child {
  margin-bottom: 0;
}
@media (min-width: 690px) {
  .banner__item {
    padding-left: 30px;
    width: 300px;
  }
}
@media (min-width: 1440px) {
  .banner__item {
    width: 500px;
    margin-bottom: 15px;
  }
}

.banner__btn {
  max-width: 150px;
  min-height: 40px;
}
@media (min-width: 690px) {
  .banner__btn {
    max-width: 250px;
    min-height: 50px;
  }
}
@media (min-width: 1440px) {
  .banner__btn {
    max-width: 250px;
    font-size: 20px;
    line-height: 20px;
  }
}

.services-content__slider {
  margin-bottom: 50px;
  position: relative;
  min-height: 400px;
}
@media (min-width: 1440px) {
  .services-content__slider {
    min-height: 700px;
  }
}

.services-content__slider-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  min-height: 350px;
}
.services-content__slider-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 170px;
  height: 350px;
  background-image: url(../img/services/phone-frame.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 170px 350px;
}
.services-content__slider-item::after {
  content: "";
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 50px;
  height: 12px;
  background-image: url(../img/svg/stars.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50px 12px;
}
@media (min-width: 1440px) {
  .services-content__slider-item {
    width: 300px;
    min-height: 600px;
  }
  .services-content__slider-item::before {
    width: 300px;
    height: 600px;
    background-size: 300px 600px;
  }
  .services-content__slider-item::after {
    width: 90px;
    height: 20px;
    background-size: 90px 20px;
  }
}

.services-content__slider-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  max-width: 150px;
  min-height: 343px;
  border-radius: 30px;
  padding: 40px 10px;
  box-shadow: 0 10px 10px #000;
}
.services-content__slider-content--bg-1 {
  background-image: url(../img/services/slide-1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px 100px;
  background-color: #1e1e1e;
}
.services-content__slider-content--bg-2 {
  background-image: url(../img/services/slide-2.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px 100px;
  background-color: #1e1e1e;
}
.services-content__slider-content--bg-3 {
  background-image: url(../img/services/slide-3.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px 100px;
  background-color: #1e1e1e;
}
.services-content__slider-content--bg-4 {
  background-image: url(../img/services/slide-4.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px 100px;
  background-color: #1e1e1e;
}
.services-content__slider-content--bg-5 {
  background-image: url(../img/services/slide-5.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px 100px;
  background-color: #1e1e1e;
}
.services-content__slider-content--bg-6 {
  background-image: url(../img/svg/polise.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px 100px;
  background-color: #1e1e1e;
}
.services-content__slider-content--bg-7 {
  background-image: url(../img/services/slide-7.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100px 130px;
  background-color: #1e1e1e;
}
.services-content__slider-content--bg-8 {
  background-image: url(../img/services/slide-8.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 200px 150px;
  background-color: #1e1e1e;
}
.services-content__slider-content--bg-9 {
  background-image: url(../img/services/slide-9.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100px 130px;
  background-color: #1e1e1e;
}
.services-content__slider-content--bg-10 {
  background-image: url(../img/services/slide-10.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px 100px;
  background-color: #1e1e1e;
}
.services-content__slider-content--bg-11 {
  background-image: url(../img/services/slide-11.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 130px 100px;
  background-color: #1e1e1e;
}
.services-content__slider-content--bg-12 {
  background-image: url(../img/services/slide-12.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px 100px;
  background-color: #1e1e1e;
}
.services-content__slider-content--bg-13 {
  background-image: url(../img/services/slide-13.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px 100px;
  background-color: #1e1e1e;
}
.services-content__slider-content--bg-14 {
  background-image: url(../img/services/slide-14.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px 100px;
  background-color: #1e1e1e;
}
.services-content__slider-content--bg-15 {
  background-image: url(../img/services/slide-15.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px 100px;
  background-color: #1e1e1e;
}
.services-content__slider-content--bg-16 {
  background-image: url(../img/services/slide-16.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px 100px;
  background-color: #1e1e1e;
}
@media (min-width: 1440px) {
  .services-content__slider-content {
    max-width: 290px;
    min-height: 590px;
    border-radius: 50px;
    padding: 70px 25px;
    box-shadow: 0 20px 20px #000;
    font-size: 15px;
    line-height: 18px;
  }
  .services-content__slider-content--bg-1 {
    background-size: 250px 150px;
  }
  .services-content__slider-content--bg-2 {
    background-size: 250px 150px;
  }
  .services-content__slider-content--bg-3 {
    background-size: 250px 150px;
  }
  .services-content__slider-content--bg-4 {
    background-size: 250px 150px;
  }
  .services-content__slider-content--bg-5 {
    background-size: 250px 150px;
  }
  .services-content__slider-content--bg-6 {
    background-size: 250px 150px;
  }
  .services-content__slider-content--bg-7 {
    background-size: 250px 270px;
  }
  .services-content__slider-content--bg-8 {
    background-size: 300px 250px;
  }
  .services-content__slider-content--bg-9 {
    background-size: 200px 250px;
  }
  .services-content__slider-content--bg-10 {
    background-size: 250px 150px;
  }
  .services-content__slider-content--bg-11 {
    background-size: 230px 150px;
  }
  .services-content__slider-content--bg-12, .services-content__slider-content--bg-13, .services-content__slider-content--bg-14, .services-content__slider-content--bg-15, .services-content__slider-content--bg-16 {
    background-size: 250px 150px;
  }
}

.services-content__slider-title {
  margin: 0;
  text-align: center;
  font-weight: 400;
  margin-bottom: auto;
}

.services-content__slider-prise {
  display: flex;
  column-gap: 5px;
  margin-bottom: 10px;
}

.services-content__slider-btn {
  position: relative;
  z-index: 1;
  width: 140px;
  min-height: 30px;
}
@media (min-width: 1440px) {
  .services-content__slider-btn {
    width: 250px;
    max-width: 250px;
    min-height: 50px;
  }
}

@media (min-width: 834px) {
  .services-content__slider__pagination {
    display: none;
  }
}

@media (min-width: 834px) {
  .services-content__slider__prev {
    left: 2%;
  }
}

@media (min-width: 834px) {
  .services-content__slider__next {
    right: 2%;
  }
}

.coolNumber-intro__title {
  margin-bottom: 20px;
}
@media (min-width: 1440px) {
  .coolNumber-intro__title {
    margin-bottom: 50px;
  }
}

.coolNumber-intro__content {
  text-align: center;
  margin-bottom: 50px;
}

.coolNumber-intro__img {
  margin-bottom: 20px;
  object-fit: cover;
}
@media (min-width: 690px) {
  .coolNumber-intro__img {
    height: 300px;
  }
}
@media (min-width: 1440px) {
  .coolNumber-intro__img {
    height: 450px;
  }
}

.coolNumber-intro__descr {
  margin: 0;
  margin-bottom: 20px;
}
.coolNumber-intro__descr:last-child {
  margin-bottom: 0;
}
@media (min-width: 834px) {
  .coolNumber-intro__descr {
    width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1440px) {
  .coolNumber-intro__descr {
    width: 1000px;
  }
}

.coolNumber-slider {
  margin-bottom: 50px;
  background-color: #1e1e1e;
  padding: 25px 0;
}
@media (min-width: 834px) {
  .coolNumber-slider {
    margin-bottom: 100px;
  }
}

.coolNumber-slider__list {
  padding: 0;
}

.coolNumber-slider__item {
  width: 250px;
  height: 250px;
}
@media (min-width: 834px) {
  .coolNumber-slider__item {
    height: 300px;
  }
}
@media (min-width: 1440px) {
  .coolNumber-slider__item {
    height: 350px;
  }
}

.coolNumber-slider__img {
  display: block;
  height: 100%;
  object-fit: fill;
}

.coolNumber-slider__btn--prev {
  left: 1%;
}
.coolNumber-slider__btn--next {
  right: 1%;
}

.coolNumber-doc {
  margin-bottom: 50px;
}
@media (min-width: 834px) {
  .coolNumber-doc {
    margin-bottom: 100px;
  }
}

.coolNumber-doc__title {
  margin-bottom: 20px;
}
@media (min-width: 690px) {
  .coolNumber-doc__title {
    margin-bottom: 50px;
  }
}

.coolNumber-doc__list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  counter-reset: numbering;
}
@media (min-width: 834px) {
  .coolNumber-doc__list {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
  }
}
@media (min-width: 1440px) {
  .coolNumber-doc__list {
    gap: 0;
    justify-content: space-between;
  }
}

.coolNumber-doc__item {
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
  width: 250px;
  height: 250px;
  border: 2px solid #f08951;
  border-radius: 20px;
  counter-increment: numbering;
  padding: 20px 10px;
  text-align: center;
}
.coolNumber-doc__item::before {
  content: "№" counter(numbering);
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -5%);
}
.coolNumber-doc__item:nth-child(1) {
  background: url(../img/svg/cool-number-doc-1.svg) center/80px no-repeat #1e1e1e;
}
.coolNumber-doc__item:nth-child(2) {
  background: url(../img/svg/cool-number-doc-2.svg) center/80px no-repeat #1e1e1e;
}
.coolNumber-doc__item:nth-child(3) {
  background: url(../img/svg/cool-number-doc-3.svg) center/80px no-repeat #1e1e1e;
}
.coolNumber-doc__item:nth-child(4) {
  background: url(../img/svg/cool-number-doc-4.svg) center/80px no-repeat #1e1e1e;
}
@media (min-width: 834px) {
  .coolNumber-doc__item {
    width: 300px;
    height: 300px;
  }
}
@media (min-width: 1440px) {
  .coolNumber-doc__item {
    font-size: 17px;
    line-height: 18px;
    width: 270px;
    height: 270px;
  }
}

.coolNumber-deal__center {
  text-align: center;
}

.coolNumber-deal__content-title {
  margin-bottom: 20px;
}
@media (min-width: 1440px) {
  .coolNumber-deal__content-title {
    margin-bottom: 50px;
  }
}

.coolNumber-deal__content {
  margin-bottom: 50px;
}
@media (min-width: 1440px) {
  .coolNumber-deal__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .coolNumber-deal__content:nth-child(3n) {
    flex-direction: row-reverse;
  }
}

.coolNumber-deal__desc {
  margin-bottom: 20px;
}
@media (min-width: 1440px) {
  .coolNumber-deal__desc {
    width: 550px;
    margin-bottom: 0;
  }
}

.coolNumber-deal__title {
  margin-bottom: 5px;
}

.coolNumber-deal__img {
  height: 380px;
}
@media (min-width: 834px) {
  .coolNumber-deal__img {
    height: 600px;
  }
}

.coolNumber-problems__title {
  margin-bottom: 50px;
}
.coolNumber-problems__title-second {
  position: relative;
  margin: 0;
  padding-top: 25px;
  color: #f08951;
  font-weight: 400;
  margin-bottom: 20px;
}
.coolNumber-problems__title-second::before {
  content: "№" counter(num);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  color: #f08951;
}
@media (min-width: 834px) {
  .coolNumber-problems__title-second {
    margin: 0;
    width: 50%;
    padding: 50px 20px;
  }
}

.coolNumber-problems__list {
  text-align: center;
  counter-reset: num;
}
@media (min-width: 834px) {
  .coolNumber-problems__list {
    margin-bottom: 50px;
  }
}

.coolNumber-problems__item {
  counter-increment: num;
  margin-bottom: 50px;
}
@media (min-width: 834px) {
  .coolNumber-problems__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1e1e1e;
    margin: 0;
  }
  .coolNumber-problems__item:nth-child(2) {
    flex-direction: row-reverse;
  }
}

@media (min-width: 834px) {
  .coolNumber-problems__desc {
    width: 50%;
    background-color: #f08951;
    padding: 50px 20px;
  }
}

.important-information {
  position: relative;
  background-color: #1e1e1e;
  border-top: 2px solid #f08951;
  border-bottom: 2px solid #f08951;
  min-height: 450px;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}
.important-information::after, .important-information::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translate(-50%, -5%);
  background-position: center;
  background-repeat: no-repeat;
}
.important-information::before {
  top: 5%;
  width: 150px;
  height: 80px;
  background-image: url(../img/svg/car-svg.svg);
  background-size: 150px 80px;
}
.important-information::after {
  bottom: 5%;
  width: 75px;
  height: 20px;
  background-image: url(../img/svg/stars.svg);
  background-size: 75px 20px;
}
@media (min-width: 834px) {
  .important-information {
    min-height: 500px;
    margin-bottom: 100px;
  }
  .important-information::before {
    width: 200px;
    height: 100px;
    background-size: 200px 100px;
  }
  .important-information::after {
    width: 100px;
    height: 30px;
    background-size: 100px 30px;
  }
}
@media (min-width: 1440px) {
  .important-information::before {
    width: 250px;
    height: 150px;
    background-size: 250px 150px;
  }
}

.important-information__desc {
  text-align: center;
}
@media (min-width: 834px) {
  .important-information__desc {
    padding-top: 50px;
    width: 700px;
  }
}
@media (min-width: 1440px) {
  .important-information__desc {
    padding-top: 100px;
  }
}
@supports (-webkit-appearance:none){
.tick::before{background-image:url(../img/svg/sprite/tick.svg)}
.header{background-image:url(../img/slides/header-banner.webp)}
.nav{background-image:url(../img/svg/stars.svg)}
.about-us__content--icon-1{background-image:url(../img/svg/about-us-1.svg)}
.about-us__content--icon-2{background-image:url(../img/svg/about-us-2.svg)}
.about-us__content--icon-3{background-image:url(../img/svg/about-us-3.svg)}
.about-us__content--icon-4{background-image:url(../img/svg/about-us-4.svg)}
.about-us__content--icon-5{background-image:url(../img/svg/about-us-5.svg)}
.about-us__content--icon-6{background-image:url(../img/svg/about-us-6.svg)}
.services__item::before{background-image:url(../img/services/phone-frame.webp)}
.services__item::after{background-image:url(../img/svg/stars.svg)}
.reviews__content::before{background-image:url(../img/svg/stars.svg)}
.reviews__avtors::after{background-image:url(../img/svg/user.svg)}
.contacts__btn-open{background-image:url(../img/svg/stars.svg)}
.footer{background-image:url(../img/svg/stars.svg)}
.link-mail::after{background-image:url(../img/svg/mail.svg)}
.footer__tel::after{background-image:url(../img/svg/tel.svg)}
.production-numbers__description--bg{background-image:url(../img/catalog/production-numbers-1.webp)}
.production-numbers__item::after{background-image:url(../img/svg/doc-item.svg)}
.production-numbers__slide:nth-child(2){background-image:url(../img/slides/slide-4-mob.webp)}
.production-numbers__slide:nth-child(3){background-image:url(../img/slides/slide-5-mob.webp)}
.production-numbers__slide:nth-child(4){background-image:url(../img/slides/slide-6-mob.webp)}
.production-numbers__slide-content--map::after{background-image:url(../img/svg/slide-map.svg)}
.certificate-popup{background-image:url(../img/catalog/phone-frame.webp)}
.products-card__item{background-image:url(../img/svg/stars.svg)}
.products-card__tip{background-image:url(../img/svg/stars.svg)}
.banner-bg-1{background-image:url(../img/services/banner-1.webp)}
.banner-bg-2{background-image:url(../img/services/banner-2.webp)}
.banner-bg-3{background-image:url(../img/services/banner-3.webp)}
.services-content__slider-item::before{background-image:url(../img/services/phone-frame.webp)}
.services-content__slider-item::after{background-image:url(../img/svg/stars.svg)}
.services-content__slider-content--bg-1{background-image:url(../img/services/slide-1.webp)}
.services-content__slider-content--bg-2{background-image:url(../img/services/slide-2.webp)}
.services-content__slider-content--bg-3{background-image:url(../img/services/slide-3.webp)}
.services-content__slider-content--bg-4{background-image:url(../img/services/slide-4.webp)}
.services-content__slider-content--bg-5{background-image:url(../img/services/slide-5.webp)}
.services-content__slider-content--bg-6{background-image:url(../img/svg/polise.svg)}
.services-content__slider-content--bg-7{background-image:url(../img/services/slide-7.webp)}
.services-content__slider-content--bg-8{background-image:url(../img/services/slide-8.webp)}
.services-content__slider-content--bg-9{background-image:url(../img/services/slide-9.webp)}
.services-content__slider-content--bg-10{background-image:url(../img/services/slide-10.webp)}
.services-content__slider-content--bg-11{background-image:url(../img/services/slide-11.webp)}
.services-content__slider-content--bg-12{background-image:url(../img/services/slide-12.webp)}
.services-content__slider-content--bg-13{background-image:url(../img/services/slide-13.webp)}
.services-content__slider-content--bg-14{background-image:url(../img/services/slide-14.webp)}
.services-content__slider-content--bg-15{background-image:url(../img/services/slide-15.webp)}
.services-content__slider-content--bg-16{background-image:url(../img/services/slide-16.webp)}
.coolNumber-doc__item:nth-child(1){background:url(../img/svg/cool-number-doc-1.svg) center/80px no-repeat #1e1e1e}
.coolNumber-doc__item:nth-child(2){background:url(../img/svg/cool-number-doc-2.svg) center/80px no-repeat #1e1e1e}
.coolNumber-doc__item:nth-child(3){background:url(../img/svg/cool-number-doc-3.svg) center/80px no-repeat #1e1e1e}
.coolNumber-doc__item:nth-child(4){background:url(../img/svg/cool-number-doc-4.svg) center/80px no-repeat #1e1e1e}
.important-information::before{background-image:url(../img/svg/car-svg.svg)}
.important-information::after{background-image:url(../img/svg/stars.svg)}
}

 @media (min-width: 834px){

@supports (-webkit-appearance:none){
.services-introduction__item--traces::after{background-image:url(../img/svg/traces.svg)}
}

}

 @media (min-width: 390px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 490px){

@supports (-webkit-appearance:none){
.production-numbers__slide:nth-child(2){background-image:url(../img/slides/slide-4-tab.webp)}
.production-numbers__slide:nth-child(3){background-image:url(../img/slides/slide-5-tab.webp)}
.production-numbers__slide:nth-child(4){background-image:url(../img/slides/slide-6-tab.webp)}
}

}

 @media (min-width: 690px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 790px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 1140px){

@supports (-webkit-appearance:none){
.production-numbers__slide:nth-child(2){background-image:url(../img/slides/slide-4.webp)}
.production-numbers__slide:nth-child(3){background-image:url(../img/slides/slide-5.webp)}
.production-numbers__slide:nth-child(4){background-image:url(../img/slides/slide-6.webp)}
}

}

 @media (min-width: 1340px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 1440px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 833px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 619px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 620px){

@supports (-webkit-appearance:none){

}

}

 @media (min-width: 1640px){

@supports (-webkit-appearance:none){

}

}
