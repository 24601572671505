* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  &:hover {
    transition: all 0.3s;
  }
}

*::before,
*::after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  width: 100%;
  object-fit: contain;
}

dl,
dt,
dd {
  margin: 0;
  padding: 0;
}

.page {
  font-family: 'GG-Wolfram';
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  background-color: $background;
  color: $white;
  overflow-x: hidden;
  @include tablet {
    font-size: 20px;
    line-height: 24px;
  }
}

.container {
  max-width: 310px;
  margin: 0 auto;
  padding: 0 5px;
  @include breakpoint390 {
    max-width: 380px;
  }
  @include breakpoint490 {
    max-width: 470px;
    padding: 0 10px;
  }
  @include breakpoint690 {
    max-width: 670px;
  }
  @include breakpoint790 {
    max-width: 770px;
  }
  @include tablet {
    max-width: 814px;
  }
  @include breakpoint1140 {
    max-width: 1040px;
    padding: 0 50px;
  }
  @include breakpoint1340 {
    max-width: 1240px;
  }
  @include desktop {
    max-width: 1340px;
  }
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.btn {
  border: 2px solid $orange;
  border-radius: 30px;
  background-color: transparent;
  color: $white;
  padding: 0 10px;
  &:hover,
  &:hover .link-icon {
    color: $orange;
    fill: $orange;
  }
  &:active {
    @include opacity-7;
  }
}

.btn-link {
  @include flex-all-center;
  max-width: 200px;
  min-height: 50px;
  @include desktop {
    max-width: 200px;
  }
}

.link-icon {
  width: 10px;
  height: 10px;
  fill: $white;
  margin-left: 5px;
  @include tablet {
    width: 15px;
    height: 15px;
  }
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}

.title {
  margin: 0;
  font-size: 25px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 1px;
  @include tablet {
    font-size: 35px;
    line-height: 48px;
  }
  @include desktop {
    font-size: 48px;
    line-height: 57px;
  }
  &-second {
    margin: 0;
    font-size: 20px;
    line-height: 25px;
    @include tablet {
      font-size: 25px;
      line-height: 30px;
    }
  }
}

.description {
  margin: 0;
}

.strong {
  color: $orange;
}

.tick {
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 15px;
    height: 15px;
    background-image: url(../img/svg/sprite/tick.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px 15px;
  }
}

.uppercase {
  text-transform: uppercase;
}

.animation-left {
  animation: ainimation-left 2s;
}

@keyframes ainimation-left {
  0% {
    transform: translate(-100%);
  }
}

.animation-top {
  animation: ainimation-top 2s;
}

@keyframes ainimation-top {
  0% {
    background-color: rgba($black, 0);
    transform: translate(0, -100%);
  }
  100% {
    background-color: rgba($black, 0.8);
  }
}

.ainimation-popup {
  animation: ainimation-popup 2s;
}

@keyframes ainimation-popup {
  0% {
    transform: translate(100%, -50%);
  }
}

.ainimation-product-cart {
  animation: ainimation-product-cart 1s;
}

@keyframes ainimation-product-cart {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ainimation-scale {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
