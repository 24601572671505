.photo-gallery {
  @include margin-bottom-50;
  position: relative;
}

.photo-gallery__title {
  @include margin-bottom-50;
}

.photo-gallery__content {
  background-color: $black-bg;
  padding: 25px 0;
}

.photo-gallery__list {
  padding: 0;
}

.photo-gallery__item {
  width: 250px;
  height: 250px;
  @include tablet {
    height: 300px;
  }
  @include desktop {
    height: 350px;
  }
}

.photo-gallery__img {
  display: block;
  height: 100%;
  object-fit: fill;
}

.photo-gallery__btn {
  &--prev {
    left: 1%;
  }
  &--next {
    right: 1%;
  }
}
